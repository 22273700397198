"use strict";

import { Scrollables } from "./includes/scrollables";
import { Clickables } from "./includes/clickables";
import { Expandables } from "./includes/expandables";
import { DepartmentDropdown } from "./includes/department-dropdown";
import { ConfirmationModal } from "./includes/confirmation-modal";
import { MediaCenter } from "./includes/media-center";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css"; // You can also use <link> for styles

document.addEventListener("DOMContentLoaded", () => {
  const anchors = [...document.querySelectorAll("a")];

  if (anchors.length > 0) {
    anchors.forEach((anchor) => {
      if (anchor.getAttribute("target") == "_blank" && !anchor.hasAttribute("data-file")) {
        anchor.addEventListener("click", (e) => {
          if (
            confirm(
              "You are now leaving the Prothya Biosolutions website. The website you are visiting may be subject to different terms of use and legislation"
            )
          ) {
          } else {
            e.preventDefault();
          }
        });
      }
    });
  }

  const mobileMenuTriggers = document.querySelectorAll(".mobile-menu__trigger");
  const mobileMenu = document.querySelector(".mobile-menu");

  const searchMenuTriggers = document.querySelectorAll(".header__search-trigger");
  const searchMenu = document.querySelector(".header__search");

  mobileMenuTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      mobileMenu.classList.toggle("is-open");
    });
  });

  searchMenuTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      searchMenu.classList.toggle("is-open");
    });
  });

  new Scrollables();
  new Clickables();
  new Expandables();
  new DepartmentDropdown();
  new ConfirmationModal();
  new MediaCenter();

  AOS.init({
    duration: 900,
    offset: 340,
  });
});
