import Swiper, { Scrollbar, Mousewheel } from "swiper";
import "../../../../node_modules/swiper/swiper-bundle.min.css";

Swiper.use([Scrollbar, Mousewheel]);

export class Scrollables {
  constructor() {
    this.valuesScrollable = document.querySelector(".values__illustration > .swiper");
    this.historyScrollable = document.querySelector(".history__illustration > .swiper");
    this.jobsScrollable = document.querySelector(".jobs__images > .swiper");
    this.patientProductsScrollable = document.querySelector(".patient-products__items .swiper");
    this.partnershipsScrollable = document.querySelector(".partnerships__partners .swiper");
    this.jobsImagesScrollable = document.querySelector(".jobs-image-slider .swiper");

    if (this.valuesScrollable) {
      this.initValuesScrollable();
      window.addEventListener("resize", this.initValuesScrollable());
    }

    // if (this.historyScrollable && window.innerWidth > 959) {
    //   this.initHistoryScrollable();
    //   window.addEventListener("resize", this.initHistoryScrollable());
    // }

    if (this.jobsScrollable) {
      this.initJobsScrollable();
      window.addEventListener("resize", this.initJobsScrollable());
    }

    if (this.patientProductsScrollable) {
      this.initPatientProductsScrollable();
      window.addEventListener("resize", this.initPatientProductsScrollable());
    }

    if (this.partnershipsScrollable) {
      this.initPartnershipScrollable();
      window.addEventListener("resize", this.initPartnershipScrollable());
    }

    if (this.jobsImagesScrollable) {
      this.initJobsImagesScrollable();
      window.addEventListener("resize", this.initJobsImagesScrollable());
    }

    // this.mouseEventListeners();
  }

  initValuesScrollable() {
    new Swiper(this.valuesScrollable, {
      cssMode: true,
      observer: true,
      observeParents: true,
      scrollbar: {
        el: this.valuesScrollable.querySelector(".swiper-scrollbar"),
        draggable: true,
        dragClass: "swiper-scrollbar-custom",
      },
      breakpoints: {
        320: {
          slidesPerView: 0.35,
        },
        640: {
          slidesPerView: 1,
        },
      },
    });
  }

  initHistoryScrollable() {
    new Swiper(this.historyScrollable, {
      direction: "vertical",
      touchReleaseOnEdges: true,
      slidesPerView: 0.5,
      scrollbar: {
        el: this.historyScrollable.querySelector(".swiper-scrollbar"),
        draggable: true,
        dragClass: "swiper-scrollbar-custom",
      },
    });
  }

  initJobsScrollable() {
    new Swiper(this.jobsScrollable, {
      cssMode: true,
      observer: true,
      observeParents: true,
      direction: window.innerWidth < 960 ? "horizontal" : "vertical",
      scrollbar: {
        el: this.jobsScrollable.querySelector(".swiper-scrollbar"),
        draggable: true,
        dragClass: "swiper-scrollbar-custom",
      },
      breakpoints: {
        320: {
          spaceBetween: 16,
          slidesPerView: window.innerWidth < 960 ? 1.5 : 2.5,
        },
      },
    });
  }

  initPatientProductsScrollable() {
    new Swiper(this.patientProductsScrollable, {
      cssMode: false,
      scrollbar: false,
      spaceBetween: 16,
      observer: true,
      observeParents: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 3,
        },
      },
    });
  }

  initPartnershipScrollable() {
    new Swiper(this.partnershipsScrollable, {
      cssMode: true,
      direction: "vertical",
      slidesPerView: 8,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      scrollbar: {
        el: this.partnershipsScrollable.querySelector(".swiper-scrollbar"),
        draggable: true,
        dragClass: "swiper-scrollbar-custom",
      },
    });
  }

  initJobsImagesScrollable() {
    new Swiper(this.jobsImagesScrollable, {
      cssMode: true,
      observer: true,
      observeParents: true,
      scrollbar: {
        el: this.jobsImagesScrollable.querySelector(".swiper-scrollbar"),
        draggable: true,
        dragClass: "swiper-scrollbar-custom",
      },
      spaceBetween: 16,
      breakpoints: {
        320: {
          slidesPerView: 1.6,
          spaceBetween: 16,
        },
        640: {
          slidesPerView: 3.4,
        },
        912: {
          slidesPerView: 4.5,
        },
      },
    });
  }

  mouseEventListeners() {
    const swipers = document.querySelectorAll(".swiper");
    if (swipers.length > 0) {
      swipers.forEach((instance) => {
        //
      });
    }
  }
}
