export class Expandables {
  constructor() {
    this.cdmoServiceExpandButtons = document.querySelectorAll(".cdmo-services__card-overlay");
    this.productDownloadables = document.querySelectorAll(".pdp-downloadables__expand-btn");
    this.productFaqQuestions = document.querySelectorAll(".pdp-faq__question-title");
    this.productReadMore = document.querySelectorAll(".page-header__readmore.is-js");

    this.jobsLanguageSelectors = document.querySelectorAll("ul.language-selector button");

    if (this.cdmoServiceExpandButtons.length > 0) {
      this.cdmoServiceExpandButtons.forEach((button) => {
        button.addEventListener("click", (e) => {
          this.handleCdmoService(e);
        });
      });
    }

    if (this.productFaqQuestions.length > 0) {
      this.productFaqQuestions.forEach((button) => {
        button.addEventListener("click", (e) => {
          this.handleProductFaqQuestion(e);
        });
      });
    }

    if (this.productDownloadables.length > 0) {
      this.productDownloadables.forEach((button) => {
        button.addEventListener("click", (e) => {
          this.handleProductDownloadables(e);
        });
      });
    }

    if (this.productReadMore.length > 0) {
      this.productReadMore.forEach((button) => {
        button.addEventListener("click", (e) => {
          this.handleProductReadMore(e);
        });
      });
    }

    if (this.jobsLanguageSelectors.length > 0) {
      this.jobsLanguageSelectors.forEach((button) => {
        button.addEventListener("click", (e) => {
          this.jobsLanguageSelectors.forEach((btn) => {
            btn.classList.remove("active");
          });

          e.target.classList.add("active");
          this.handleJobsLanguageSelectors(e);
        });
      });
    }
  }

  handleCdmoService(e) {
    const target = e.target;
    const list = e.target.previousElementSibling;
    if (list.matches(".cdmo-services__card-list")) {
      list.style.maxHeight = "unset";
      target.remove();
    }
  }

  handleProductFaqQuestion(e) {
    const chevron = e.target.querySelector(".pdp-faq__question-chevron");
    const list = e.target.nextElementSibling;
    if (list.matches(".pdp-faq__question-answer")) {
      list.classList.toggle("pdp-faq__question-answer--visible");
      chevron.classList.toggle("pdp-faq__question-chevron--is-open");
    }
  }

  handleProductDownloadables(e) {
    const list = e.target.previousElementSibling;
    if (list.matches(".pdp-downloadables__list")) {
      list.classList.toggle("pdp-downloadables__list--is-open");
      e.target.remove();
    }
  }

  handleProductReadMore(e) {
    e.target.remove();
    const readMoreContent = document.querySelector(".page-header__extra-content");
    if (readMoreContent) {
      readMoreContent.style.display = "block";
    }
  }

  handleJobsLanguageSelectors(e) {
    const contentContainers = document.querySelectorAll(".content-multilang");

    contentContainers.forEach((container) => {
      container.style.display = "none";
      if (e.target.classList.contains("lang-en")) {
        document.querySelector(".content-multilang.english").style.display = "block";
        document.querySelector(".content-disclaimer.english").style.display = "block";
      }
      if (e.target.classList.contains("lang-nl")) {
        document.querySelector(".content-multilang.dutch").style.display = "block";
        document.querySelector(".content-disclaimer.dutch").style.display = "block";
      }
    });
  }
}
