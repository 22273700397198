export class Clickables {
  constructor() {
    this.historyReadMore = document.querySelector(".history__readmore");

    if (this.historyReadMore) {
      this.historyReadMore.addEventListener("click", () => {
        this.historyReadMoreHandler();
      });
    }
  }

  historyReadMoreHandler() {
    const historyIllustrationContainer = document.querySelector(
      ".history__illustration"
    );
    const historyIllustrationContainerFade = window.getComputedStyle(
      historyIllustrationContainer,
      ":after"
    );

    historyIllustrationContainer.style.maxHeight = "none";
    this.historyReadMore.remove();
    this.historyIllustrationContainerFade.remove();
  }
}
