export class MediaCenter {
  constructor() {
    this.buttons = document.querySelectorAll(".media-center__menu--item-btn");
    this.articleLists = document.querySelectorAll("[data-cpt]");

    if (this.buttons.length > 0) {
      this.buttons.forEach((button) => {
        button.addEventListener("click", (e) => {
          // Button
          this.buttons.forEach((btn) => btn.classList.remove("active"));
          e.target.classList.add("active");

          // List
          const target = e.target.getAttribute("data-target");
          this.articleLists.forEach((list) => list.classList.remove("active"));
          document.querySelector(`[data-cpt=${target}]`).classList.add("active");
        });
      });
    }
  }
}
