export class DepartmentDropdown {
  constructor() {
    const departmentDropdown = document.getElementById("departmentDropdown");

    if (departmentDropdown) {
      const deparmentContactInfo = document.querySelector(".department-info");
      const deparmentContactEmailContainer = deparmentContactInfo.querySelector(".department-info__item--email");
      const deparmentContactEmail = deparmentContactInfo.querySelector(".department-info__item--email a");
      const deparmentContactPhoneContainer = deparmentContactInfo.querySelector(".department-info__item--phone");
      const deparmentContactPhone = deparmentContactInfo.querySelector(".department-info__item--phone a");

      departmentDropdown.addEventListener("change", (e) => {
        deparmentContactInfo.style.display = "block";
        deparmentContactEmailContainer.style.display = "none";
        deparmentContactPhoneContainer.style.display = "none";
        let option = document.querySelector(`option[value='${e.target.value}']`);
        let email = option.getAttribute("data-email");
        let phone = option.getAttribute("data-phone");

        if (email != "") {
          deparmentContactEmailContainer.style.display = "block";
          deparmentContactEmail.href = `mailto:${email}`;
          deparmentContactEmail.innerHTML = email;
        }
        if (phone != "") {
          deparmentContactPhoneContainer.style.display = "block";
          deparmentContactPhone.href = `tel:${phone}`;
          deparmentContactPhone.innerHTML = phone;
        }
      });
    }
  }
}
