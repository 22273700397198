export class ConfirmationModal {
  constructor() {
    const modal = document.querySelector(".confirmation-modal");
    const productOverview = document.getElementById("ProductOverview");
    if (modal) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      const accept = modal.querySelector("button.accept");
      const decline = modal.querySelector("button.decline");

      accept.addEventListener("click", () => {
        document.body.style.position = "unset";
        document.body.style.width = "unset";
        modal.remove();

        if (window.location.hash == "#ProductOverview" && productOverview) {
          scrollTo(document.body, productOverview.offsetTop, 600);
        }
      });

      decline.addEventListener("click", () => {
        document.location.href = "/";
      });
    }
  }
}
